import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AntD from 'ant-design-vue'
// import 'ant-design-vue/'
import 'ant-design-vue/dist/antd.css';
import * as echarts from 'echarts'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import { download } from './utils/request'

Vue.use(Vant);

Vue.use(AntD)
Vue.use(ElementUI, { size: 'small' })

Vue.config.productionTip = false

Vue.prototype.$echarts = echarts
Vue.prototype.download = download

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
