<template>
  <div class="login-page">
    <div class="login-box" v-loading="isLoading">
      <h3 class="title">多点网络广告管理平台</h3>
      <div class="form-item">
        <div class="form-label">Username</div>
        <div class="input-box">
          <input
            type="text"
            v-model="username"
            placeholder="Please type username"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-label">Password</div>
        <div class="input-box">
          <input
            type="password"
            v-model="password"
            placeholder="Please type Password"
          />
        </div>
      </div>

      <div class="button" @click="toLogin">Login</div>
    </div>
  </div>
</template>
    
<script>
import api from "@/utils/api";
export default {
  data() {
    return {
      username: "",
      password: "",
      isLoading: false,
    };
  },
  methods: {
    async toLogin() {
      //   this.$router.push("/layout");
      //   return;
      this.isLoading = true;
      this.$store
        .dispatch("Login", {
          username: this.username,
          password: this.password,
        })
        .then((data) => {
          this.isLoading = false;
          this.$toast("Login is successful!");
          localStorage.setItem("TOKEN", data.token);
          localStorage.setItem("USER_NAME", this.username);
          this.$router.push("/layout/dashboard");
        });
    },
  },
};
</script>
<style lang="less" scoped>
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .login-box {
    padding: 20px;
    border-radius: 16px;
    background-color: #fff;
    width: 448px;

    .title {
      font-size: 18px;
      font-weight: 600;
      padding-bottom: 20px;
      border-bottom: 1px solid #c7c9f3;
    }

    .form-item {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 10px 0;
      .form-label {
        font-size: 14px;
        margin-bottom: 4px;
      }

      .input-box {
        border-radius: 8px;
        border: 1px solid #c7c9f3;
        overflow: hidden;
        width: 100%;

        input {
          padding: 4px 8px;
          outline: none;
          border: 0;
          height: 44px;
          width: 100%;
        }
      }
    }

    .button {
      background: linear-gradient(270deg, #4f85ff 0%, #4458fe 100%);
      border-radius: 8px;
      color: #fff;
      font-size: 14px;
      height: 44px;
      line-height: 44px;
      margin-top: 40px;
      cursor: pointer;
    }
  }
}
</style>