<template>
  <div class="left-menu">
    <div class="logo">
      <div class="logo-image"></div>
    </div>
    <div class="menu-list">
      <div
        class="menu-item"
        :class="{ active: currentIndex === index }"
        v-for="(item, index) in menuList"
        :key="index"
        @click="changeMenu(index, item)"
      >
        <img class="icon" :src="item.icon" alt="" />
        <img class="icon active" :src="item.activeIcon" alt="" />
        <img
          src="../assets/images/left-menu/active.png"
          alt=""
          class="active-icon"
        />
        <p class="text">{{ item.text }}</p>
      </div>
    </div>

    <a-drawer
      placement="left"
      :width="240"
      :closable="false"
      :mask="false"
      :visible="visible"
      @close="onClose"
    >
      <div class="left-menu more">
        <div class="logo">
          <div class="logo-image"></div>
        </div>
        <div class="menu-list">
          <div
            class="menu-item"
            :class="{ active: currentIndex === index }"
            v-for="(item, index) in menuList"
            :key="index"
            @click="changeMenuByMore(index, item)"
          >
            <img class="icon" :src="item.icon" alt="" />
            <img class="icon active" :src="item.activeIcon" alt="" />
            <img
              src="../assets/images/left-menu/active.png"
              alt=""
              class="active-icon"
            />
            <p class="text">{{ item.text }}</p>
          </div>
        </div>

        <div class="sub-menu">
          <div class="sub-menu-list">
            <div
              class="sub-menu-item"
              v-for="(item, index) in subMenuList"
              :key="index"
              v-show="!item.hidden"
              @click="toSubModule(item)"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </a-drawer>
  </div>
</template>
<script>
export default {
  name: "left-menu",
  data() {
    return {
      visible: false,
      currentIndex: 0,
      subMenuList: [],
      menuList: [
        {
          icon: require("../assets/images/left-menu/1.png"),
          activeIcon: require("../assets/images/left-menu/1-active.png"),
          text: "工作台",
          link: "/layout/dashboard",
        },
        {
          icon: require("../assets/images/left-menu/2.png"),
          activeIcon: require("../assets/images/left-menu/2-active.png"),
          text: "用户",
          link: "/layout/user",
        },
        {
          icon: require("../assets/images/left-menu/3.png"),
          activeIcon: require("../assets/images/left-menu/3-active.png"),
          text: "权限",
          childList: [
            { text: "菜单管理", link: "/layout/menu" },
            { text: "角色管理", link: "/layout/role" },
            { text: "组织架构" },
            { text: "字段管理" },
          ],
        },
        {
          icon: require("../assets/images/left-menu/4.png"),
          activeIcon: require("../assets/images/left-menu/4-active.png"),
          text: "客户",
          link: "/layout/customer",
        },
        {
          icon: require("../assets/images/left-menu/5.png"),
          activeIcon: require("../assets/images/left-menu/5-active.png"),
          text: "账户",
          childList: [
            { text: "账户列表", link: "/layout/accounts" },
            { text: "账户开户" },
            { text: "账户申诉" },
          ],
        },
        {
          icon: require("../assets/images/left-menu/6.png"),
          activeIcon: require("../assets/images/left-menu/6-active.png"),
          text: "充值",
          childList: [{ text: "充值列表" }, { text: "充值记录" }],
        },
        {
          icon: require("../assets/images/left-menu/7.png"),
          activeIcon: require("../assets/images/left-menu/7-active.png"),
          text: "渠道",
          link: "/layout/media",
        },
        {
          icon: require("../assets/images/left-menu/8.png"),
          activeIcon: require("../assets/images/left-menu/8-active.png"),
          text: "财务",
          childList: [{ text: "我的钱包" }, { text: "退款记录" }],
        },
      ],
    };
  },
  watch: {
    $route(val) {
      this.setCurrentRoute();
    },
  },
  created() {
    this.setRoute();
    this.setCurrentRoute();
  },
  methods: {
    setRoute() {
      const route = this.$store.getters.permission_routes;
      const filterRoute = route.filter((item) => item.query);
      this.menuList = filterRoute.map((item) => {
        if (item.redirect !== "noRedirect") {
          const [current] = item.children ? item.children : [];
          if (current) {
            item.text = current.meta.title;
            item.icon = current.meta.icon;
            item.activeIcon = item.query;
            item.link = current.path;
          }
        } else {
          item.text = item.meta.title;
          item.icon = item.meta.icon;
          item.activeIcon = item.query;
          item.link = "";
          item.childList = item.children.map((it) => {
            it.text = it.meta.title;
            it.link = it.path;
            return it;
          });
        }
        return item;
      });
    },
    setCurrentRoute() {
      console.log(this.$route.path);
      const current = this.menuList.findIndex((item) => {
        if (item.childList) {
          return item.childList.some((it) => it.link === this.$route.path);
        } else {
          return item.link === this.$route.path;
        }
      });

      this.currentIndex = current;
    },
    toSubModule(item) {
      this.visible = false;
      if (this.$route.path === item.link) {
        return;
      }
      localStorage.setItem("CURRENT_MENU", item.text);

      this.$router.push(item.link);
    },
    onClose() {
      this.visible = false;
    },
    changeMenu(index, item) {
      this.currentIndex = index;
      this.subMenuList = item.childList;

      if (item.childList && item.childList.length > 0) {
        this.visible = true;
      } else if (item.link && this.$route.fullPath !== item.link) {
        this.$router.push(item.link);
        localStorage.setItem("CURRENT_MENU", item.text);
      }
    },
    changeMenuByMore(index, item) {
      this.currentIndex = index;
      this.subMenuList = item.childList;
      if (!item.childList) {
        this.visible = false;
        if (item.link && this.$route.fullPath !== item.link) {
          this.$router.push(item.link);
          localStorage.setItem("CURRENT_MENU", item.text);
        }
      } else if (item.link && this.$route.fullPath !== item.link) {
        this.$router.push(item.link);
        localStorage.setItem("CURRENT_MENU", item.text);
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-drawer-body {
  padding: 0;
  height: 100%;
}
.left-menu {
  width: 70px;
  height: 100%;
  .logo {
    width: 70px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo-image {
      width: 30px;
      height: 30px;
      background-color: #4f85ff;
      border-radius: 50%;
    }
  }

  &.more {
    width: 240px;
    height: 100%;
    position: relative;

    .sub-menu {
      position: absolute;
      right: 0;
      top: 50px;
      width: 100px;
      height: calc(100% - 50px);
      display: flex;
      justify-content: center;
      align-items: center;

      .sub-menu-list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .sub-menu-item {
          width: 72px;
          height: 24px;
          color: #2b2d49;
          font-size: 12px;
          margin-bottom: 8px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background-color: #ebedff;
            color: #4458fe;
            border-radius: 4px;
            cursor: pointer;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .logo {
      justify-content: flex-start;
      padding: 0 20px;
      box-sizing: border-box;
      width: 140px;
      background-color: #f3f6fe;
    }

    .menu-list {
      width: 140px;
      background-color: #f3f6fe;
      height: calc(100% - 50px);
      .menu-item {
        width: 140px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-left: 26px;
        box-sizing: border-box;

        &.active {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .text {
          margin-top: 0;
          margin-left: 12px;
        }
      }
    }
  }

  .menu-list {
    .menu-item {
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;

      .active-icon {
        display: none;
      }

      .icon {
        width: 24px;
        height: 24px;

        &.active {
          display: none;
        }
      }

      .text {
        color: #5b6389;
        font-size: 12px;
        line-height: 18px;
        margin: 4px 0 0;
      }

      &.active {
        background-color: #fff;
        border-radius: 16px;
        position: relative;
        cursor: default;

        .active-icon {
          position: absolute;
          left: 0;
          top: 24px;
          width: 4px;
          height: 22px;
          display: block;
        }

        & .icon {
          display: none;
          &.active {
            display: block;
          }
        }

        .text {
          color: #4458fe;
        }
      }
    }
  }
}
</style>