import axios from 'axios'
import { Toast, Dialog } from 'vant';
import router from '../router/index'
import { saveAs } from 'file-saver'
import { Loading, Message } from 'element-ui';

const TIMEOUT = 30000
const BASE_URL = 'https://duodian-network.com/api'
// const BASE_URL = ''


const service = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
})

service.interceptors.request.use(config => {
  const token = localStorage.getItem('TOKEN')
  if (token) {
    config.headers.Authorization = token
  }
  return config
})

service.interceptors.response.use(response => {
  const data = response.data
  if (Object.prototype.toString.call(data) === '[object Blob]') {
    return data
  } else {
    try {
      if (data.code === 200) {
        return data
      } else if (data.code === 401) {
        Toast({
          title: 'Tips',
          message: '登录已失效,请重新登录',
          confirmButtonText: "OK",
        })
        localStorage.setItem("TOKEN", '')
        router.push('/login')
      } else {
        const message = data.msg
        if (message) {
          Toast({
            title: "Tips",
            message: message,
            confirmButtonText: "OK",
          })
        } else {
          Toast({
            title: "Tips",
            message: 'System error!',
            confirmButtonText: "OK",
          })
        }

        return Promise.reject(data)
      }
    } catch (e) {
      Toast({
        title: "Tips",
        message: 'System error!',
        confirmButtonText: "OK",
      })
    }
  }

}, error => {

  try {
    const message = error.response.data.msg
    if (message) {
      // Toast.fail(message)
    } else {
      Toast({
        title: "Tips",
        message: 'System error!',
        confirmButtonText: "OK",
      })
    }

    return Promise.reject(error)
  } catch (e) {
    Toast({
      title: "Tips",
      message: 'System error!',
      confirmButtonText: "OK",
    })
  }

})

/**
* 参数处理
* @param {*} params  参数
*/
export function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && value !== "" && typeof (value) !== "undefined") {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result
}

// 验证是否为blob格式
export function blobValidate(data) {
  return data.type !== 'application/json'
}

let downloadLoadingInstance;

export function download(url, params, filename, config) {
  downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
  return service.post(url, params, {
    transformRequest: [(params) => { return tansParams(params) }],
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    ...config
  }).then(async (data) => {
    const isBlob = blobValidate(data);
    if (isBlob) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      Message.error(errMsg);
    }
    downloadLoadingInstance.close();
  }).catch((r) => {
    console.error(r)
    Message.error('下载文件出现错误，请联系管理员！')
    downloadLoadingInstance.close();
  })
}

export default service