<template>
  <div class="top-nav">
    <div class="left">
      <img
        class="home-icon"
        @click="toHome"
        src="../assets/images/home-icon.png"
        alt=""
      />
      <span class="split">/</span>
      <p>{{ currentPage }}</p>
    </div>
    <div class="info">
      <img src="../assets/images/question.png" alt="" class="icon" />
      <img src="../assets/images/tips.png" alt="" class="icon" />
      <el-dropdown @command="handleCommand">
        <div class="user-info">
          <div class="user">
            <img src="../assets/images/user-avatar.png" alt="" class="avatar" />
            <p>{{ userName }}</p>
          </div>
          <img src="../assets/images/more-icon.png" alt="" class="more" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  name: "topNav",
  data() {
    return {
      currentPage: "工作台",
      userName: "",
    };
  },
  watch: {
    $route(val) {
      const currentPage = localStorage.getItem("CURRENT_MENU");
      this.currentPage = currentPage;
    },
  },
  created() {
    this.userName = localStorage.getItem("USER_NAME");
  },
  methods: {
    toHome() {
      if (this.$route.path === "/layout/dashboard") return;
      localStorage.setItem("CURRENT_MENU", "工作台");
      this.$router.push("/layout/dashboard");
    },
    handleCommand(command) {
      if (command === "logout") {
        this.$store.dispatch("LogOut").then((res) => {
          this.$message.success("退出登录成功!");
          this.$router.push("/login");
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.top-nav {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .home-icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    .split {
      color: #5b6389;
      font-size: 14px;
      margin: 0 4px;
    }

    p {
      color: #5b6389;
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .icon {
      width: 20px;
      height: 20px;
      margin-right: 30px;
    }

    .user-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      border-radius: 8px;
      width: 160px;
      height: 32px;
      padding: 0 10px 0 4px;
      box-sizing: border-box;

      .more {
        width: 12px;
        height: 12px;
      }

      .user {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .avatar {
          width: 22px;
          height: 22px;
        }

        p {
          color: #5b6389;
          font-size: 14px;
          margin: 0 0 0 8px;
        }
      }
    }
  }
}
</style>