<template>
  <div class="layout" v-loading="isLoading">
    <left-menu></left-menu>
    <div class="right">
      <top-nav></top-nav>
      <router-view />
    </div>
  </div>
</template>
<script>
import LeftMenu from "../components/LeftMenu.vue";
import TopNav from "../components/TopNav.vue";

export default {
  components: { "left-menu": LeftMenu, TopNav },
  data() {
    return {
      isLoading: false,
    };
  },
};
</script>
<style lang="less">
.el-button--primary {
  background: linear-gradient(270deg, #4f85ff 0%, #4458fe 100%);
  border: 0;
}

.el-table {
  width: 100%;

  .el-table__header {
    .el-table__cell {
      background-color: #f3f6fe;
      height: 54px;
      box-sizing: border-box;
    }
  }
}

.el-button--text {
  color: #4458fe;
}
</style>